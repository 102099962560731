<template>
  <view-item title="定损员业绩报表">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="月报表" name="statistics">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="statistics.table.daily" :model="statistics.form.data">
                    <en-form-item label="结算日期">
                      <en-date-picker
                        v-model:start="statistics.form.data.settlementStartYearMonth"
                        v-model:end="statistics.form.data.settlementEndYearMonth"
                        type="monthrange"
                        value-format="YYYY-MM"
                      ></en-date-picker>
                    </en-form-item>

                    <en-form-item label="门店">
                      <select-maintain
                        v-model="statistics.form.data.branchIds"
                        :ajax="{
                          action: 'GET /enocloud/common/branch',
                          params: (params) => {
                            params.payload = { pagingEnabled: false }
                          }
                        }"
                        :props="{ label: 'shortName', value: 'id' }"
                        clearable
                        multiple
                      ></select-maintain>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="ACDMTHSTM"
                        :height="height"
                        :data="statistics.table.data"
                        :loading="statistics.table.loading"
                        :paging="statistics.table.paging"
                        :summary="statistics.table.summary.data"
                        :method="statistics.table.daily"
                        pagination
                        show-summary
                        :config="statistics.table.config"
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                        <template #DATE="{ row }: { row: EnocloudStatementDefinitions['ServiceAccidentSettlePersonSummaryDto'] }">
                          {{ formatDatem(row.date) }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="年报表" name="indetail">
              <div :style="{ height: `${height - 55}px` }" class="settle-person-for-form-query">
                <div class="h-full">
                  <form-query :method="indetail.table.daily" :model="indetail.form.data">
                    <en-form-item label="结算日期">
                      <en-date-picker v-model="indetail.form.data.settlementStartYear" type="year" value-format="YYYY"></en-date-picker>
                      <span :style="{ paddingRight: '4px' }">-</span>
                      <en-date-picker v-model="indetail.form.data.settlementEndYear" type="year" value-format="YYYY"></en-date-picker>
                    </en-form-item>

                    <en-form-item label="门店">
                      <select-maintain
                        v-model="indetail.form.data.branchIds"
                        :ajax="{
                          action: 'GET /enocloud/common/branch',
                          params: (params) => {
                            params.payload = { pagingEnabled: false }
                          }
                        }"
                        :props="{ label: 'shortName', value: 'id' }"
                        clearable
                        multiple
                      ></select-maintain>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="ACDANLSTM"
                        :height="height"
                        :data="indetail.table.data"
                        :loading="indetail.table.loading"
                        :paging="indetail.table.paging"
                        :summary="indetail.table.summary.data"
                        :method="indetail.table[indetail.form.data.mode]"
                        pagination
                        show-summary
                        :config="indetail.table.config"
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                        <template #DATE="{ row }: { row: EnocloudStatementDefinitions['ServiceAccidentSettlePersonSummaryDto'] }">
                          {{ formatDatey(row.date, true) }}
                        </template>

                        >
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import { omit, pick } from 'lodash-es'

export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              monthly: {
                action: 'GET /enocloud/statement/service/accident/settle/person/monthly/export',
                params(params) {
                  params.payload = omit(this.statistics.form.data, 'mode')
                }
              },
              year: {
                action: 'GET /enocloud/statement/service/accident/settle/person/year/export',
                params(params) {
                  params.payload = omit(this.indetail.form.data, 'mode')
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'statistics':
                  this.operation.export.monthly()
                  this.store.openDownload()
                  break
                case 'indetail':
                  this.operation.export.year()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      tabs: {
        active: 'statistics'
      },
      statistics: {
        children: {
          form: {
            data: {
              settlementStartYearMonth: '',
              settlementEndYearMonth: '',
              settlingPerson: '',
              branchIds: []
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/service/accident/settle/person/monthly',
                summary: 'GET /enocloud/statement/service/accident/settle/person/monthly/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(
                    this.statistics.form.data,
                    'settlementStartYearMonth',
                    'settlementEndYearMonth',
                    'branchIds',
                    'settlingPerson',
                    'claimsCompany'
                  )
                }
              }
            },
            config: {
              SETTLING_PERSON: { header: { filter: { type: 'text', field: 'settlingPerson' } } },
              CLAIMS_COMPANY: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'claimsCompany',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/hint/:hintTypeStr',
                        params: (params) => (params.paths = ['SASCMC'])
                      },
                      props: { label: 'name', value: 'name' }
                    }
                  }
                }
              }
            }
          }
        }
      },
      indetail: {
        children: {
          form: {
            data: {
              settlementStartYear: '',
              settlementEndYear: '',
              claimsCompanyId: '',
              settlingPerson: '',
              branchIds: []
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/service/accident/settle/person/year',
                summary: 'GET /enocloud/statement/service/accident/settle/person/year/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(
                    this.indetail.form.data,
                    'settlementStartYear',
                    'settlementEndYear',
                    'claimsCompanyId',
                    'settlingPerson',
                    'branchIds'
                  )
                }
              }
            },
            config: {
              SETTLING_PERSON: { header: { filter: { type: 'text', field: 'settlingPerson' } } },
              CLAIMS_COMPANY: {
                header: {
                  filter: {
                    type: 'select',
                    field: 'claimsCompany',
                    props: {
                      ajax: {
                        action: 'GET /enocloud/common/hint/:hintTypeStr',
                        params: (params) => (params.paths = ['SASCMC'])
                      },
                      props: { label: 'name', value: 'id' }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.statistics.table.daily()
    this.indetail.table.daily()
  }
})
</script>

<style>
.settle-person-for-form-query .form-query .el-form-item .el-form-item__content {
  width: 454px;
}
.settle-person-for-form-query .form-query .el-form-item .el-form-item__content .el-input__wrapper {
  width: 194px;
}
</style>
